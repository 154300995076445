<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ on }">
      <v-list-item
        v-on="on"
      >
        <v-list-item-action class="mr-4">
          <v-icon
            key="fal fa-lock-open fa-fw"
            small
          >
            fal fa-lock-open fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Unapprove
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-form
      ref="unapproveForm"
      v-model="valid"
      @submit.prevent="unapprove(item)"
    >
      <v-card>
        <v-card-title>
          <v-icon
            small
            class="mr-2"
          >
            fas fa-lock-open
          </v-icon>
          Unapprove Interpretation
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-textarea
            v-model="reason"
            label="Why does this report need to be unapproved?"
            :rules="[v => !!v || $t('required')]"
            outlined
          />
          <v-text-field
            v-model="confirmation"
            label="Confirm the Result ID"
            color="success"
            :rules="[
              v => !!v || $t('required'),
              v => v === labResult?.external_id || 'Result ID does not match'
            ]"
            outlined
          />
          <v-alert
            color="warning"
            dark
            icon="fa fa-exclamation-triangle fa-fw"
          >
            This does not guarantee that the lab will receive the updated report, or be able to replace the one already sent.
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            text
            @click="dialog = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            type="submit"
            color="error"
          >
            Unapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
  export default {
    props: {
      labResult: {
        type: Object,
        default: () => ({}),
        required: true,
      },
    },
    data: () => ({
      dialog: false,
      valid: false,
      loading: false,
      reason: '',
      confirmation: '',
    }),
    methods: {
      unapprove () {
        this.loading = true
        this.axios
          .post('lab_results/' + this.labResult.uuid + '/interpretation/unapprove', {
            reason: this.reason,
          })
          .then(() => {
            this.$emit('unapproved')
          })
          .finally(() => {
            this.loading = false
            this.dialog = false
          })
        },
      },
  }
</script>


